import React from "react";
import "typeface-montserrat";
import { Typography } from "antd";

const addWhiteProp = (Comp) => ({ white, ...props }) => (
  <Comp css={{ ...(white && { color: "white !important" }) }} {...props} />
);

export const titleCss = {
  textTransform: "uppercase",
  letterSpacing: "0.1em",
  fontFamily: `Montserrat, "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};

export const Title = addWhiteProp(({ ...props }) => (
  <Typography.Title css={titleCss} {...props} />
));

export const Paragraph = addWhiteProp(Typography.Paragraph);
export const Link = addWhiteProp(Typography.Link);
export const Text = addWhiteProp(Typography.Text);
