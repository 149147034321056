import translations from "./translations";

export const DEFAULT_LANG = "en";

const translatedLangsMap = {};

Object.keys(translations).forEach((key) => {
  Object.keys(translations[key]).forEach((lang) => {
    if (lang !== DEFAULT_LANG) translatedLangsMap[lang] = true;
  });
});

export const translatedLangs = Object.keys(translatedLangsMap);
