import React from "react";
import { mX, pX } from "./spacingShorthands";

export default ({ size = "lg", ...props }) => (
  <div
    css={{
      ...mX("auto"),
      ...pX(16),
      width: "100%",
      maxWidth: {
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      }[size],
    }}
    {...props}
  />
);
