/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { Layout, Space, Select } from "antd";
import tinycolor from "tinycolor2";
import { FacebookFilled, LinkedinFilled } from "@ant-design/icons";
import logo from "../images/contreeb-logo-white.svg";
import leaves from "../images/leaves.jpg";
import { getPreferedLang, setPreferedLang } from "../lang/preferedLang";
import { DEFAULT_LANG } from "../lang/supportedLangs";
import LangCtx from "../lang/LangCtx";
import { darkGreen } from "../theme/colors";
import { Title, Paragraph } from "./typography";
import Container from "./Container";
import { translationsByLang } from "../lang/useTranslation";
import mq from "./mq";
import useRedirectToPreferedLang from "../lang/useRedirectToPreferedLang";

const { Header, Footer } = Layout;

const darkGreen084 = tinycolor(darkGreen).setAlpha(0.84).toRgbString();
const leavesBg = {
  backgroundColor: darkGreen,
  backgroundImage: `linear-gradient(0deg, ${darkGreen084}, ${darkGreen084}), url(${leaves})`,
  backgroundSize: "cover",
};

export default (
  ContentComponent,
  { HeaderMenu = () => null, logoLink = "/" } = {}
) => ({
  pageContext: { initialLang = DEFAULT_LANG, ...pageContext },
  ...props
}) => {
  const [lang, setLang] = useState(initialLang);

  useRedirectToPreferedLang(lang);
  useEffect(() => {
    setLang(getPreferedLang());
  }, []);

  return (
    <LangCtx.Provider value={lang}>
      <Helmet defaultTitle="Contreeb" titleTemplate="%s | Contreeb">
        <html lang={lang} />
        <meta name="description" content="Climate-friendy products" />
      </Helmet>
      <Layout
        css={{
          minHeight: "100vh",
          backgroundColor: "white",
          overflowX: "hidden",
        }}
      >
        <Header
          css={{
            ...leavesBg,
            padding: 0,
            position: "fixed",
            zIndex: 1,
            width: "100%",
          }}
        >
          <Container
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Link to={logoLink} state={{ scrollTo: "main" }}>
              <img
                css={mq({ height: ["2em", "2.5em"], display: "block" })}
                src={logo}
                alt="Contreeb"
              />
            </Link>
            <HeaderMenu />
          </Container>
        </Header>
        <div
          id="main"
          css={{
            paddingTop: 64,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContentComponent pageContext={pageContext} {...props} />
        </div>
        <Footer
          css={{
            textAlign: "center",
            ...leavesBg,
            color: "white",
          }}
        >
          <Title white level={4}>
            {translationsByLang[lang].findUsOn}
          </Title>
          <Paragraph>
            <Space size="middle" css={{ fontSize: "2.5em" }}>
              <a
                href="https://www.linkedin.com/company/contreeb/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinFilled />
              </a>
              <a
                href="https://www.facebook.com/contreeb"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookFilled />
              </a>
            </Space>
          </Paragraph>
          <Paragraph>
            <Select
              onChange={(l) => {
                setPreferedLang(l);
                setLang(l);
              }}
              value={lang}
            >
              <Select.Option value="fr">🇫🇷 Français</Select.Option>
              <Select.Option value="en">🇬🇧 English</Select.Option>
            </Select>
          </Paragraph>
          <Paragraph css={{ color: "white" }}>
            © Contreeb {new Date().getFullYear()}
          </Paragraph>
        </Footer>
      </Layout>
    </LangCtx.Provider>
  );
};
