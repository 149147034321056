export default {
  required: {
    en: "Required",
    fr: "Requis",
  },
  invalid: {
    en: "Invalid",
    fr: "Invalide",
  },

  heroTitle: {
    en: "Start selling climate-friendly products",
    fr: "Vendez des produits bons pour le climat",
  },
  subHero1: {
    en: "Your customers want to shop greener.",
    fr: "Vos clients veulent des produits responsables.",
  },
  subHero2: {
    en: "Climate action is now more than ever in line with your objectives.",
    fr: "Agir pour le climat n'a jamais été autant aligné avec vos objectifs.",
  },

  notFoundText: { en: "There's nothing here...", fr: "Il n'y a rien ici..." },
  returnHome: { en: "Return home", fr: "Retournez à l'accueil" },
  findUsOn: { en: "Find us on:", fr: "Retrouvez nous sur :" },

  thankYou: { en: "Thank you!", fr: "Merci !" },
  getBackToYou: {
    en: "We'll get back to you in a moment.",
    fr: "On revient vers vous très vite.",
  },

  productReportIntro: {
    en: "This is a climate footprint report for:",
    fr: "Ceci est un rapport d'empreinte climatique de:",
  },

  by: {
    en: "by",
    fr: "par",
  },

  contreebCertifies: {
    en: "Contreeb hereby certifies that:",
    fr: "Contreeb certifie par la présente que :",
  },

  hasDoneLca: {
    en:
      "has performed a precise upstream Life Cycle Assessement (LCA) of the product:",
    fr:
      "a effectuée une précise Analyse du Cycle de Vie (ACV) amont du produit :",
  },

  andFunds: {
    en: "and funds the above project by the following amount per item sold:",
    fr:
      "et finance le project ci-dessus du montant suivant par article vendu :",
  },

  ghgEmissions: {
    en: "Greenhouse gases emissions",
    fr: "Emissions de gaz à effet de serre",
  },

  environmentalCost: {
    en: "Environmental cost",
    fr: "Coût pour l'environnement",
  },

  supportedProject: {
    en: "Funded project",
    fr: "Projet financé",
  },

  perTon: {
    en: "per ton",
    fr: "par tonne",
  },

  perProduct: {
    en: "per product",
    fr: "par produit",
  },

  pwlessLoginExpl: {
    en:
      "If you don't have an account yet, we'll create one for you. We use a passwordless login, which is more secrure and saves you from remembering yet another password. Just click the magic link we'll email you.",
    fr:
      "Si vous n'avez pas encore de compte, nous en créerons un pour vous. Nous utilisons une connexion sans mot de passe, ce qui est plus sécurisé et vous évite de vous souvenir d'un mot de passe de plus. Cliquez simplement sur le lien magique que nous vous enverrons par mail.",
  },

  logIn: {
    en: "Log in",
    fr: "Connexion",
  },

  account: {
    en: "Account",
    fr: "Compte",
  },

  getStarted: {
    en: "Get started",
    fr: "Commencer",
  },

  loginSuccessful: {
    en: "Login successful",
    fr: "Connexion réussie",
  },

  invalidExpiredLink: {
    en: "Invalid or expired link",
    fr: "Lien invalide ou périmé",
  },

  youMayClose: {
    en: "You may close this window.",
    fr: "Vous pouvez fermer cette fenêtre.",
  },

  loginCode: {
    en:
      "We have sent you an email to confirm your login request, with the following code:",
    fr:
      "Nous vous avons envoyé un e-mail pour confirmer votre demande de connexion, avec le code suivant:",
  },

  waitForLoginConfirm: {
    en:
      "Waiting for you to click on the email link... After that, this page will automatically unlock.",
    fr:
      "En attente que vous cliquiez sur le lien de l'e-mail... Après ça, cette page se déverrouillera automatiquement.",
  },

  perMonth: {
    en: "per month",
    fr: "par mois",
  },

  freePlan: {
    en: {
      name: "Free",
      features: [
        "For testing purposes",
        "1 team member",
        "1 product",
        "Private product report",
        "No project funding",
      ],
    },
    fr: {
      name: "Gratuit",
      features: [
        "Pour tester",
        "1 membre d'équipe",
        "1 produit",
        "Rapport produit privé",
        "Pas de financement de projet",
      ],
    },
  },

  proPlan: {
    en: {
      name: "Pro",
      features: [
        "Ready for launch",
        "3 team members",
        "10 products",
        "Public product reports",
        "Automated project funding",
      ],
    },
    fr: {
      name: "Pro",
      features: [
        "Prêt au lancement",
        "3 membres d'équipe",
        "10 produits",
        "Rapports produits publics",
        "Financement de projets automatisé",
      ],
    },
  },

  enterprisePlanName: {
    en: "Enterprise",
    fr: "Entreprise",
  },

  biggerNeeds: {
    en: "You have bigger or custom needs? We'll be happy to help you!",
    fr:
      "Vous avez des besoins plus conséquents ou personnalisés ? Nous serons heureux de vous accompagner !",
  },

  letsTalk: {
    en: "Let's talk",
    fr: "Parlons-nous",
  },

  getFootprint: {
    en: "Get the climate impact of one of your products",
    fr: "Obtenez l'impact climatique d'un de vos produits",
  },

  workEmail: {
    en: "Your work email",
    fr: "Votre email professionel",
  },

  saveSaveSave: {
    en: "Save time. Save money. Save the planet.",
    fr: "Préservez votre temps, votre argent, et la planète.",
  },

  ctaQuestions: {
    en: {
      howLong: {
        q: "How long?",
        a: "We're a start up, we're very quick to respond.",
      },
      howMuch: {
        q: "How much?",
        a:
          "During our launch it only costs €99 (that's way less than doing it yourself).",
      },
    },
    fr: {
      howLong: {
        q: "En combien de temps?",
        a: "Nous sommes une start up, nous sommes très reactifs.",
      },
      howMuch: {
        q: "Pour combien?",
        a:
          "Pendant notre lancement ça ne coûte que 99€ (c'est bien moins cher que de le faire vous même).",
      },
    },
  },

  mission: { en: "Mission", fr: "Mission" },
  product: { en: "Product", fr: "Produit" },
  pricing: { en: "Pricing", fr: "Tarif" },
  about: { en: "About", fr: "A propos" },

  you: { en: "You?", fr: "Vous ?" },
  wereHiring: { en: "We’re hiring!", fr: "On recrute !" },
  joinTeam: { en: "Join the team", fr: "Rejoignez-nous" },

  ah: {
    en: [
      "Co-founder & CEO",
      "Ex-Criteo Sales Manager",
      "5 years experience in Sales BtoB",
    ],
    fr: [
      "Co-fondateur & CEO",
      "Ex-Criteo Sales Manager",
      "5 ans en Business Development",
    ],
  },
  gr: {
    en: [
      "Co-founder & CTO",
      "Engineer from Centrale Paris",
      "3x startup founder",
    ],
    fr: [
      "Co-fondateur & CTO",
      "Ingénieur Centrale Paris",
      "3 startups fondées",
    ],
  },

  productDescription: {
    en: "A platform to tackle climate change upon purchase in 3 simple steps.",
    fr:
      "Une plateforme pour lutter contre le changement climatique à l'acte d'achat en 3 étapes simples.",
  },

  productSection: {
    en: [
      {
        title: "Measurement",
        content:
          "Life Cycle Assessment is often viewed as complicated but with Contreeb it does not have to be. We provide the numbers and interfaces you need to put together the sum of the emissions induced by a product. All this at a cost (in terms of time and money) 10x inferior than it used to be.",
      },
      {
        title: "Carbon pricing",
        content:
          "We guide you in stating a cost of carbon, based on best-practices, possible regulations evolutions and state-of-the-art scientific studies. Putting a price on carbon emissions means addressing the environmental cost of your product by funding carbon reduction and capture projets, that resonate with your brand's story.",
      },
      {
        title: "Communication",
        content:
          "We publish unique links (for product pages of an online shop) and barcodes (for product tags in physical stores) certifying as a trusted third party that the funding is actually done. So consumers have confidence in their choices to trust your brand. And you are safe from greenwashing accusations.",
      },
    ],
    fr: [
      {
        title: "Mesure",
        content:
          "L'analyse du cycle de vie est souvent considérée comme compliquée, mais avec Contreeb, plus maintentant. Nous vous fournissons les chiffres et les interfaces dont vous avez besoin pour constituer la somme des émissions induites par un produit. Et c'est 10 fois plus efficace qu'avant (en termes de temps et d'argent).",
      },
      {
        title: "Prix du carbone",
        content:
          "Nous vous guidons dans la détermination d'un prix du carbone, basé sur les meilleures pratiques, les possibles évolutions réglementaires et les dernières études scientifiques. Fixer un prix sur les émissions de carbone, c'est agir à hauteur du coût environnemental de votre produit en finançant des projets de réduction et de séquestration du carbone, qui résonnent avec l'histoire de votre marque.",
      },
      {
        title: "Communication",
        content:
          "Nous publions des liens unique (pour les pages produits d'une boutique en ligne) et des code-barres (pour les étiquettes produits dans les magasins physiques) certifiant en tant que tiers de confiance que le financement est réellement effectué. Les consommateurs ont donc foi dans leur choix de votre marque. Et vous êtes à l'abri des accusations de \"greenwashing\".",
      },
    ],
  },

  missionDescription: {
    en: "Accelerate the transition to a low-carbon world. Here is why:",
    fr: "Accélérer la transition vers un monde bas-carbone. Voici pourquoi :",
  },

  missionSection: {
    en: [
      {
        title: "Climate change",
        content:
          "Climate change is the most serious threat that mankind has had to face in a long time. The consequences will be catastrophic: a warming of only 2°C will lead to damages exceeding $69 trillion (Moody's).",
      },
      {
        title: "Awareness",
        content:
          "Consumers are shifting their habits and reorienting their purchases towards greener businesses and products. They are willing to pay a significant premium for more sustainable products. And since this trend is led by millenials and Gen Z, it will only get stronger, like organic food.",
      },
      {
        title: "Expectations",
        content:
          "It’s a daunting challenge for our industries to keep up with climate and consumer expectations. In our fast-paced information world, passivity or bad communication is never far from shaming and greenwashing accusations.",
      },
    ],
    fr: [
      {
        title: "Changement climatique",
        content:
          "Le changement climatique est la menace la plus grave à laquelle l'humanité ait dû faire face depuis longtemps. Les conséquences seront catastrophiques : un réchauffement de seulement 2°C entraînera des dommages dépassant 69 trillions de dollars (Moody's).",
      },
      {
        title: "Prise de conscience",
        content:
          "Les consommateurs modifient leurs habitudes et réorientent leurs achats vers des entreprises et des produits plus écologiques. Ils sont prêts à investir davantage pour des produits plus durables. Et puisque cette tendance est dirigée par les millenials et la génération Z, elle ne fera que se renforcer, de la même manière que le bio.",
      },
      {
        title: "Exigences",
        content:
          "Répondre aux attentes du climat et des consommateurs représente un défi de taille pour nos industries. Dans notre monde où l'information circule toujours plus vite, la passivité ou la mauvaise communication n'est jamais loin de scandales et d'accusations de \"greenwashing\".",
      },
    ],
  },

  purHexagoneDescription: {
    en:
      "PUR Hexagone is a program aiming at planting trees and shrubs in parts of France, in close partnership with farmers and land owners willing to improve their crops systems through agroforestry.",
    fr:
      "PUR Hexagone est un programme de plantation d’arbres et arbustes, mené en France, en partenariat avec des producteurs et propriétaires souhaitant faire évoluer leur systèmes culturaux en s’engageant dans des pratiques agroforestières.",
  },

  climateFacts: {
    en: "Climate Facts",
    fr: "Valeur Climatique",
  },

  climateAction: {
    en: "Climate action",
    fr: "Action climatique",
  },

  rawMaterials: {
    en: "Raw materials",
    fr: "Matières premières",
  },

  transportation: {
    en: "Transportation",
    fr: "Transport",
  },

  manufacturing: {
    en: "Manufacturing",
    fr: "Fabrication",
  },

  packaging: {
    en: "Packaging",
    fr: "Emballage",
  },

  funding: {
    en: "Funding",
    fr: "Financement",
  },
};
