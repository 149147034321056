import { useContext } from "react";
import LangCtx from "./LangCtx";
import { DEFAULT_LANG } from "./supportedLangs";
import translations from "./translations";

export const translationsByLang = {};

Object.keys(translations).forEach((key) => {
  Object.keys(translations[key]).forEach((lang) => {
    translationsByLang[lang] = translationsByLang[lang] || {};
    translationsByLang[lang][key] = translations[key][lang];
  });
});

export default (key) => {
  const lang = useContext(LangCtx);
  const t = (k) =>
    translationsByLang[lang][k] || translationsByLang[DEFAULT_LANG][k] || k;
  return key ? t(key) : t;
};
