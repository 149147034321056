import { useEffect } from "react";
import { translatedLangs, DEFAULT_LANG } from "./supportedLangs";
import localizedPaths from "./localizedPaths";
import { getPreferedLang } from "./preferedLang";
import { useNavigate, useLocation } from "../components/reach-router";

const removeTrailingSlash = (path) =>
  path === "/" ? path : path.replace(/\/$/, "");

export default (lang) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const match = location.pathname.match(
      new RegExp(
        `^(/(?<pathLang>${translatedLangs.join(
          "|"
        )}))?(?<genericPath>${localizedPaths.join("|")})?$`
      )
    );
    if (!match) return;
    const {
      groups: { pathLang = DEFAULT_LANG, genericPath = "/" },
    } = match;

    const preferedLang = getPreferedLang();
    if (pathLang !== preferedLang) {
      navigate(
        removeTrailingSlash(
          `${
            preferedLang === DEFAULT_LANG ? "" : `/${preferedLang}`
          }${genericPath}`
        ),
        { replace: true, state: location.state }
      );
    }
  }, [location, navigate, lang]);
};
