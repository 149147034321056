import { DEFAULT_LANG, translatedLangs } from "./supportedLangs";

export const getPreferedLang = () => {
  const savedLang = localStorage.getItem("lang");
  if (savedLang) return savedLang;
  const browserLang = (navigator.language || navigator.userLanguage).split(
    "-"
  )[0];
  if (translatedLangs.indexOf(browserLang) >= 0) return browserLang;
  return DEFAULT_LANG;
};
export const setPreferedLang = (lang) => {
  localStorage.setItem("lang", lang);
};
